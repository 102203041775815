import "../css/index.css";

import { Main } from "./Main";

export const App = () => {
  return (
    <div className="h-screen bg-[#b5e4ddc0] flex  justify-center ">
      <Main />
    </div>
  );
};
